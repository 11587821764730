'use client'

import { type PolymorphicProps, useSlotProps } from '@mui/base'
import { type ComponentProps, type ElementType, type JSX } from 'react'

type CardOwnProps<RootComponentType extends ElementType = 'div'> = {
  slotProps?: {
    root?: ComponentProps<RootComponentType>
  }

  slots?: {
    root?: RootComponentType
  }
}

interface CardTypeMap<RootComponentType extends ElementType = 'div'> {
  defaultComponent: RootComponentType
  props: CardOwnProps<RootComponentType>
}

type CardProps<
  RootComponentType extends ElementType = CardTypeMap['defaultComponent'],
> = PolymorphicProps<CardTypeMap<RootComponentType>, RootComponentType>

/**
 * Cards contain content and actions about a single subject.
 */
export const Card = <RootComponentType extends ElementType = 'div'>(
  props: CardProps<RootComponentType>,
): JSX.Element => {
  const { slotProps, slots, ...restProps } = props

  const Root = slots?.root ?? 'div'
  const rootProps = useSlotProps({
    className: 'card',
    elementType: Root,
    externalForwardedProps: restProps,
    externalSlotProps: slotProps?.root,
    ownerState: undefined,
  })

  return <Root {...rootProps} />
}
